import common from "@/common";

export default [
  {
    name: 'companyName',
    label: 'common.company',
    type: 'text',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'facilityName',
    label: 'common.facility',
    type: 'text',
    slot: 'facility',
    show: common.isAdmin(),
    edit_disable: true
  },
  {
    name: 'floorName',
    label: 'common.floor',
    type: 'text',
    slot: 'floor',
    edit_disable: true
  },
  {
    name: 'roomName',
    label: 'common.room',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'bedNumber',
    label: 'common.bed',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'memberCode',
    label: 'member.memberCode',
    type: 'text',
    edit_show: true,
    edit_disable: true
  },
  {
    name: 'chineseName',
    label: 'member.chineseName',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'englishName',
    label: 'member.englishName',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'qrCode',
    label: 'QRCode',
    type: 'text',
    edit_disable: true
  },
  {
    name: 'qrCodes',
    label: '外部 QRCode(s)',
    // rule: 'required',
    validate_in_slot: true,
    type: 'slot',
    slot: 'qrCodes',
    slot_config: {
      name: 'qrCodes',
      label: '外部 QRCode(s)',
      type: 'file',
      file_accept: '.png,.jpg,.jpeg,.jfif',
      placeholder: common.getI18n('kiosk.placeholder').replace('{_type_}', '.png, .jpg, .jpeg, .jfif'),
      rule: 'required',
      max_count: 9,
      show_del_btn: true,
      show_add_btn: true,
    },
  },
]
